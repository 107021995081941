import { ActionTree } from 'vuex';
import { RootState } from '../types';
import { DocumentUploadState } from './state';
import Cookies from 'js-cookie'; // Importar js-cookie
import axios, { AxiosResponse } from 'axios';

const actions: ActionTree<DocumentUploadState, RootState> = {
    async requestCredit({ commit }, formData: FormData): Promise<any>{
        try {
            // obtener el token de las cookies
            const token = Cookies.get('authToken'); // Recupera el token de las cookies
            if (!token) {
                throw new Error('No se encontró el token de autenticacón');
            }

            // Configurar Axios con el token de autenticación
            axios.defaults.headers.common['Authorization'] = `Token ${token}`;
            axios.defaults.withCredentials = true;

            // Realizar la solicitud al endpoint de request-credit
            const response: AxiosResponse<any> = await axios.post('https://creditosb.aikacree.com/api/auth/request-credit/', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }, 
            });

            const newToken = response.headers.authorization;

            // Si se recibe un nuevo token en la respuesta, actualizarlo
            if (newToken) {
                Cookies.set('authToken', newToken); //Actualizar el token de las cookies
                commit('auth/SET_TOKEN', newToken, { root: true }); // Actualiza el token
                axios.defaults.headers.common['Authorization'] = `Token ${newToken}`;
            }            

            // console.log('Backend response:', response.data);

            // Actualizar URLs en el estado
            // commit('SET_DOCUMENT_URLS', {
            //     documentUrl: response.data.document_url,
            //     document2Url: response.data.document2_url,
            //     nominaUrl: response.data.nomina_url,
            // });

            return response.data;   
        } catch (error) {
            console.error('Error during credit request:', error);
            throw error;      
        }
    },
};

export default actions;